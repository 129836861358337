<template>
  <section class="container">
    <div class="row justify-content-center justify-content-md-between mb-3 mr-0 ml-2 ml-sm-0">
      <mdb-btn
        flat
        dark-waves
        icon="arrow-left"
        type="button"
        title="Regresar"
        @click="$router.go(-1)"
      >
        Regresar
      </mdb-btn>
    </div>
    <div class="encabezado-detalles">
      <header class="h4-responsive text-center pt-3">Reporte</header>
      <div class="row mx-0 mt-2">
        <div class="col-12 col-sm-7">
          <small class="small-block font-titulo">Nombre del reporte</small>
          <p>
            Pedido acumulado vs Despachado
          </p>
        </div>
        <div class="col-12 col-sm">
          <small class="small-block font-titulo">Mes</small>
          <p>
            {{ fechaResumen }}
          </p>
        </div>
        <div class="col-12">
          <span class="small-block font-titulo">Descripción</span>
          <p class="mb-1">Reporte de los Pedidos realizados en el rango de fecha seleccionada y el mes anterior a esa fecha.</p>
          <p>Muestra los pedidos con estatus: En cola, incompletos y Completos.</p>
        </div>
      </div>
    </div>
    <div class="row align-items-center mb-3">
      <div class="col col-sm-6 col-xl-3">
        <mdb-input
          class="my-2"
          v-model="fechaResumen"
          type="month"
          label="Desde"
          outline
        />
      </div>
      <div class="col col-sm-6 col-xl-3">
        <mdb-input
          class="my-2"
          v-model="fechaHasta"
          type="month"
          label="Hasta"
          outline
        />
      </div>
      <div
      class="col-12 col-xl-6 px-0"
      >
        <FiltrosTipo1
          :ven="vendedoresFiltro"
          :responsive="responsive"
          @vendedorAFiltrar="vendedoresAFiltrar = $event; actualizar()"
          @eliminarFiltros="restablecerFiltros"
        />
      </div>
    </div>
    <div
      v-if="cargando"
      class="mensaje-no-items py-3"
    >
      <LoaderKel />
      <p class="texto mt-2">
        Cargando
      </p>
    </div>
    <!-- Mensaje cuando el vendedor seleccionado no tiene pedido -->
    <div
      v-else-if="!pedidos.length"
      class="mensaje-no-items pb-3"
    >
      <font-awesome-icon
        icon="clipboard-list"
        size="5x"
        class="icono"
      />
      <p class="texto">No hay despachos en la fecha seleccionada</p>
    </div>
    <ul
      v-else
      class="table despachos"
    >
      <li class="encabezado sticky">
        <div class="row mx-0">
          <div class="col-12 col-sm px-0">
            <header class="h4-responsive">Pedido acumulado vs Despachado</header>
          </div>
          <div class="col-12 col-md-auto d-flex justify-content-center align-items-center">
            <mdb-btn
              block
              flat
              dark-waves
              icon="print"
              @click="imprimirTablaPedidoVsDespachado(pedidos)"
            >
              Imprimir
            </mdb-btn>
          </div>
        </div>
        <div class="thead claro">
          <div class="col-12 col-sm col-md-2 col-xl-3">
            Producto - Descripción
            <small class="small-block">
              Código - Categoría
            </small>
          </div>
          <div class="col-12 col-sm col-md-2 text-sm-right">
            No despachado
            <p>del mes anterior</p>
            <small class="small-block">
              Cantidad
            </small>
          </div>
          <div class="col-12 col-sm col-md-2 text-sm-right">
            Pedido mes actual
            <small class="small-block">
              Cantidad
            </small>
          </div>
          <div class="col-12 col-sm col-md-2 px-xl-2 text-sm-right">
            Sumatoria
            <p>de ambos Pesos</p>
            <small class="small-block">
              Cantidad
            </small>
          </div>
          <div class="col-12 col-sm col-md-2 text-sm-right">
            Peso despachado
            <small class="small-block">
              Cantidad
            </small>
          </div>
          <div class="col-12 col-sm p-1 p-lg-2 p-xl-1 text-sm-right">
            Porcentaje
            <small class="small-block">
              cumplimiento (kg)
            </small>
          </div>
        </div>
      </li>
      <li
        class="contenido"
        v-for="p in pedidos"
        :key="p.id_pre"
      >
        <div
          class="col-12 col-sm col-md-2 col-xl-3"
          data-columna="Producto - Descripción / Código - Categoría"
        >
          <p>
            {{p.nom_pro}} -
            <span class="text-uppercase">
              {{p.des_pre}}
            </span>
            <span class="small-block">
              {{p.cod_pre}} - {{p.nom_cat}}
            </span>
          </p>
        </div>
        <div
          class="col-12 col-sm col-md-2 celda-numerica"
          data-columna="Peso / Cantidad mes anterior"
        >
          <p>
            {{Math.abs(parseFloat(p.pesoPeAnt)).toLocaleString('es')}} KG
            <span class="small-block">
              {{Math.abs(parseFloat(p.canPeAnt)).toLocaleString('es')}}
            </span>
          </p>
        </div>
        <div
          class="col-12 col-sm col-md-2 celda-numerica"
          data-columna="Peso / Cantidad mes actual"
        >
          <p>
            {{Math.abs(parseFloat(p.pesoPeAct)).toLocaleString('es')}} KG
            <span class="small-block">
              {{Math.abs(parseFloat(p.canPeAct)).toLocaleString('es')}}
            </span>
          </p>
        </div>
        <div
          class="col-12 col-sm col-md-2 px-xl-2 celda-numerica"
          data-columna="Sumatoria de ambos peso / cantidad"
        >
          <p>
            {{Math.abs(parseFloat(p.pesoTotal)).toLocaleString('es')}} KG
            <span class="small-block">
              {{Math.abs(parseFloat(p.canTotal)).toLocaleString('es')}}
            </span>
          </p>
        </div>
        <div
          class="col-12 col-sm col-md-2 celda-numerica"
          data-columna="Peso / Cantidad despachada"
        >
          <p>
            {{Math.abs(parseFloat(p.pesoDes)).toLocaleString('es')}} KG
            <span class="small-block">
              {{Math.abs(parseFloat(p.canDes)).toLocaleString('es')}}
            </span>
          </p>
        </div>
        <div
          class="col-12 col-sm p-md-1 p-lg-2 p-xl-1 celda-numerica"
          data-columna="Porcentaje de cumplimiento"
        >
          <p>
            {{porcentajeCumplimiento(p.pesoDes, p.pesoTotal)}}
          </p>
        </div>
      </li>
      <li class="contenido">
        <div
          class="col-12 col-sm col-md-2 col-xl-3 font-weight-bold text-uppercase totales-color"
          data-columna="Totales"
        >
          <p class="d-none d-md-block">
            Totales
          </p>
        </div>
        <div
          class="col-12 col-sm col-md-2 text-md-right"
          data-columna="Peso mes anterior / Cantidad mes anterior"
        >
          <p>
            {{Number(totalesPesoMesAnterior).toLocaleString('es')}} KG
            <span class="small-block">
              {{ Number(totalCantidadMesAnterior).toLocaleString('es') }}
            </span>
          </p>
        </div>
        <div
          class="col-12 col-sm col-md-2 text-md-right"
          data-columna="Peso mes actual / Cantidad mes actual"
        >
          <p>
            {{ Number(totalPesoMesActual).toLocaleString('es') }} KG
            <span class="small-block">
              {{ Number(totalCantidadMesActual).toLocaleString('es') }}
            </span>
          </p>
        </div>
        <div
          class="col-12 col-sm col-md-2 px-xl-2 text-md-right"
          data-columna="Sumatoria peso total / Sumatoria cantidad total"
        >
          <p>
            {{Number(sumatoriaPesoTotal).toLocaleString('es')}} KG
            <span class="small-block">
              {{ Number(sumatoriaCantidadTotal).toLocaleString('es') }}
            </span>
          </p>
        </div>
        <div
          class="col-12 col-sm col-md-2 text-md-right"
          data-columna="Peso despachado / Cantidad despachada"
        >
          <p>
            {{Number(totalPesoDespachado).toLocaleString('es')}} KG
            <span class="small-block">{{ Number(totalCantidadDespachada).toLocaleString('es') }}</span>
          </p>
        </div>
        <div
          class="col-12 col-sm p-1 p-lg-2 p-xl-1 text-md-right"
          data-columna="Total porcentaje cumplimiento"
        >
          <p>
            {{totalPorcentajeCumplimiento}}
          </p>
        </div>
      </li>
    </ul>
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
    <OpcionesImpresion
      :mostrar-modal="opcionesDeImpresion.mostrarModal"
      :codigoDeImpresion="opcionesDeImpresion.codigoDeImpresion"
      :nombre-documento="opcionesDeImpresion.nombreDocumento"
      @cerrar="opcionesDeImpresion.mostrarModal = $event"
    />
  </section>
</template>

<script>
import { mdbInput, mdbBtn } from 'mdbvue'
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import FiltrosTipo1 from '@/components/FiltrosTipo1.vue'
import OpcionesImpresion from '@/components/OpcionesImpresion.vue'
import { PedidovsDespachado } from '@/funciones/formatosImpresion.js'
import { apiPost } from '@/funciones/api.js'
import LoaderKel from '@/components/LoaderKel.vue'

export default {
  name: 'PedidoAcumuladoVsDespachado',
  components: {
    mdbInput,
    mdbBtn,
    AlertaMensaje,
    OpcionesImpresion,
    FiltrosTipo1,
    LoaderKel
  },
  data () {
    return {
      responsive: { btnRestablecer: 'col-xl-auto', vendedor: 'col-lg-6' },
      alertaMensaje: { contenido: '' },
      fechaResumen: new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2),
      fechaHasta: '',
      pedidos: [],
      vendedoresFiltro: [],
      vendedoresAFiltrar: [],
      opcionesDeImpresion: {
        codigoDeImpresion: [],
        mostrarModal: false,
        nombreDocumento: ''
      },
      cargando: false
    }
  },
  mounted () {
    this.actualizar()
    this.obtenerVendedoresFiltros()
  },
  watch: {
    fechaResumen () {
      this.fechaHasta = ''
      this.actualizar()
    },
    fechaHasta () {
      if (!this.fechaHasta) return
      this.actualizar()
    }
  },
  computed: {
    totalesPesoMesAnterior () {
      if (!this.pedidos.length) return 0
      return this.pedidos.reduce((total, p) => (total + p.pesoPeAnt), 0)
    },
    totalCantidadMesAnterior () {
      if (!this.pedidos.length) return 0
      return this.pedidos.reduce((total, p) => (total + p.canPeAnt), 0)
    },
    totalPesoMesActual () {
      if (!this.pedidos.length) return 0
      return this.pedidos.reduce((total, p) => (total + p.pesoPeAct), 0)
    },
    totalCantidadMesActual () {
      if (!this.pedidos.length) return 0
      return this.pedidos.reduce((total, p) => (total + p.canPeAct), 0)
    },
    sumatoriaPesoTotal () {
      if (!this.pedidos.length) return 0
      return this.pedidos.reduce((total, p) => (total + p.pesoTotal), 0)
    },
    sumatoriaCantidadTotal () {
      if (!this.pedidos.length) return 0
      return this.pedidos.reduce((total, p) => (total + p.canTotal), 0)
    },
    totalPesoDespachado () {
      if (!this.pedidos.length) return 0
      return this.pedidos.reduce((total, p) => (total + p.pesoDes), 0)
    },
    totalCantidadDespachada () {
      if (!this.pedidos.length) return 0
      return this.pedidos.reduce((total, p) => (total + p.canDes), 0)
    },
    totalPorcentajeCumplimiento () {
      if (this.totalPesoDespachado === 0) return '0%'
      return isFinite(Number(((this.totalPesoDespachado * 100) / this.sumatoriaPesoTotal).toFixed(2)))
        ? Number(((this.totalPesoDespachado * 100) / this.sumatoriaPesoTotal).toFixed(2)) + '%'
        : '0%'
    }
  },
  methods: {
    porcentajeCumplimiento (despachado, pedido) {
      if (despachado === 0) return '0%'
      return isFinite(Number(((despachado * 100) / pedido).toFixed(2)))
        ? Math.abs(Number(((despachado * 100) / pedido).toFixed(2))) + '%'
        : '0%'
    },
    restablecerFiltros () {
      this.fechaHasta = ''
      this.vendedoresAFiltrar = []
      this.fechaResumen = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2)
      this.actualizar()
    },
    obtenerVendedoresFiltros () {
      apiPost({ s: 'vendedores' }, '')
        .then((res) => {
          this.vendedoresFiltro = res.data.ven
        }).catch(() => {
          this.alertaMensaje = {
            contenido: 'Ocurrió un error obteniendo los clientes',
            tipo: 'error'
          }
        })
    },
    actualizar () {
      this.cargando = true
      let data = this.fechaResumen && this.fechaResumen.length && `y=${this.fechaResumen.split('-')[0]}&m=${this.fechaResumen.split('-')[1]}&`
      data += this.fechaResumen && this.fechaHasta && `delM=${this.fechaResumen.split('-')[1]}&delY=${this.fechaResumen.split('-')[0]}&alM=${this.fechaHasta.split('-')[1]}&alY=${this.fechaHasta.split('-')[0]}`
      this.vendedoresAFiltrar && this.vendedoresAFiltrar.length && (data += `&vendedores=${this.vendedoresAFiltrar.join(',')}`)
      apiPost({ s: 'pedidovsdespachadoMv' }, data)
        .then((r) => {
          this.pedidos = r?.data?.ele.map((e) => {
            const canPeAnt = Number(e.can_ped_ant)
            const pesoPeAnt = Number((e.can_ped_ant * e.pesm_pre).toFixed(2))
            const canPeAct = Number(e.can_ped)
            const pesoPeAct = Number((e.can_ped * e.pesm_pre).toFixed(2))
            const canTotal = Number(e.suma)
            const pesoTotal = Number((e.suma * e.pesm_pre).toFixed(2))
            const canDes = Number(e.can_sap)
            const pesoDes = Number((e.can_sap * e.pesm_pre).toFixed(2))
            return {
              nom_pro: e.nom_pro,
              des_pre: e.des_pre,
              cod_pre: e.cod_pre,
              nom_cat: e.nom_cat,
              canPeAnt,
              pesoPeAnt,
              canPeAct,
              pesoPeAct,
              canTotal,
              pesoTotal,
              canDes,
              pesoDes
            }
          })
          this.cargando = false
        }).catch(() => {
          this.alertaMensaje = {
            contenido: 'Ocurrió un error obteniendo los datos.',
            tipo: 'error'
          }
          this.cargando = false
        })
    },
    imprimirTablaPedidoVsDespachado (pedidos) {
      // Estableciendo la estructura para la tabla de pedidos
      const PEDIDOS = [
        // Fila de encabezados
        [
          {
            stack: [
              'PRODUCTO - DESCRIPCIÓN',
              {
                text: 'CÓDIGO - CATEGORÍA',
                fontSize: 8
              }
            ],
            bold: true
          },
          {
            alignment: 'right',
            stack: [
              'PESO MES ANTERIOR',
              {
                text: 'CANTIDAD',
                fontSize: 8
              }
            ],
            bold: true
          },
          {
            alignment: 'right',
            stack: [
              'PESO MES ACTUAL',
              {
                text: 'CANTIDAD',
                fontSize: 8
              }
            ],
            bold: true
          },
          {
            alignment: 'right',
            stack: [
              'PESO SUMATORIA AMBOS',
              {
                text: 'CANTIDAD',
                fontSize: 8
              }
            ],
            bold: true
          },
          {
            alignment: 'right',
            stack: [
              'PESO DESAPACHADO',
              {
                text: 'CANTIDAD',
                fontSize: 8
              }
            ],
            bold: true
          },
          {
            alignment: 'right',
            stack: [
              'PORCENTAJE',
              {
                text: 'CUMPLIMIENTO (KG)',
                fontSize: 8
              }
            ],
            bold: true
          }
        ]
      ]

      for (const pedido in pedidos) {
        PEDIDOS.push([
          {
            stack: [
              `${pedidos[pedido].nom_pro}, ${pedidos[pedido].des_pre.toUpperCase()}`,
              {
                text: `${pedidos[pedido].cod_pre}, ${pedidos[pedido].nom_cat}`,
                fontSize: 8
              }
            ]
          },
          {
            alignment: 'right',
            stack: [
              `${Math.abs(pedidos[pedido].pesoPeAnt)} KG`,
              {
                text: Math.abs(pedidos[pedido].canPeAnt),
                fontSize: 8
              }
            ]
          },
          {
            alignment: 'right',
            stack: [
              `${Math.abs(pedidos[pedido].pesoPeAct)} KG`,
              {
                text: Math.abs(pedidos[pedido].canPeAct),
                fontSize: 8
              }
            ]
          },
          {
            alignment: 'right',
            stack: [
              `${Math.abs(pedidos[pedido].pesoTotal)} KG`,
              {
                text: Math.abs(pedidos[pedido].canTotal),
                fontSize: 8
              }
            ]
          },
          {
            alignment: 'right',
            stack: [
              `${Math.abs(pedidos[pedido].pesoDes)} KG`,
              {
                text: Math.abs(pedidos[pedido].canDes),
                fontSize: 8
              }
            ]
          },
          {
            text: `${this.porcentajeCumplimiento(pedidos[pedido].canDes, pedidos[pedido].canTotal)}`,
            alignment: 'right'
          }
        ])
      }

      PEDIDOS.push(
        [
          {
            text: 'TOTALES',
            fontSize: 9
          },
          {
            alignment: 'right',
            stack: [
              `${Math.abs(Number(this.totalesPesoMesAnterior)).toLocaleString('es')} KG`,
              {
                text: `${Math.abs(Number(this.totalCantidadMesAnterior)).toLocaleString('es')}`,
                fontSize: 8
              }
            ],
            fontSize: 8
          },
          {
            alignment: 'right',
            stack: [
              `${Math.abs(Number(this.totalPesoMesActual)).toLocaleString('es')} KG`,
              {
                text: `${Math.abs(Number(this.totalCantidadMesActual)).toLocaleString('es')}`,
                fontSize: 8
              }
            ],
            fontSize: 8
          },
          {
            alignment: 'right',
            stack: [
              `${Math.abs(Number(this.sumatoriaPesoTotal)).toLocaleString('es')} KG`,
              {
                text: `${Math.abs(Number(this.sumatoriaCantidadTotal)).toLocaleString('es')}`,
                fontSize: 8
              }
            ],
            fontSize: 8
          },
          {
            alignment: 'right',
            stack: [
              `${Math.abs(Number(this.totalPesoDespachado)).toLocaleString('es')} KG`,
              {
                text: `${Math.abs(Number(this.totalCantidadDespachada)).toLocaleString('es')}`,
                fontSize: 8
              }
            ],
            fontSize: 8
          },
          {
            text: `${this.totalPorcentajeCumplimiento}`, fontSize: 8, alignment: 'right'
          }
        ]
      )
      let fechaActual = new Date().toISOString().slice(0, 19)
      fechaActual = fechaActual.split('T').join(' ')

      const fechas = {
        fechaResumen: this.fechaResumen,
        fechaImpresion: fechaActual
      }

      const { nombre, formato } = PedidovsDespachado(fechas, PEDIDOS)

      this.opcionesDeImpresion = {
        codigoDeImpresion: formato,
        nombreDocumento: nombre,
        mostrarModal: true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.table.despachos {
  margin-bottom: 3rem;

  // Define los breakpoints para el responsive de ul.table
  @include ul-table-responsive-at ("768px");
}
.totales-color {
  @media screen and (min-width: 768px) {
    color: #616161
  }
}
</style>
